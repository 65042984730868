<template>
  <div class="body" @keydown="KeyDown">
    <v-card elevation="6" rounded="xl" class="login-form align-center justify-center pa-4 mx-auto">
        <v-img
          lazy-src="@/assets/logo.png"
          max-height="150"
          contain
          src="@/assets/logo.png"
        ></v-img>
        <v-card-title class="d-block">
          Логин
        </v-card-title>
        <v-form @submit.stop.prevent="Submit">
          <v-card-text>
          <v-text-field
            color="success"
            v-model="form.username"
            label="Телефонный номер"
            :error-messages="emailErrors"
          ></v-text-field>
          <v-text-field
            color="success"
            v-model="form.password"
            label="Пароль"
            :error-messages="passwordErrors"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
          ></v-text-field>
          </v-card-text>
          <v-card-text v-show="errors != ''" class="error--text">
            {{ errors }}
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="success" block depressed @click="Submit">Логин <v-icon right>mdi-arrow-right</v-icon></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { USER_LOGIN } from "@/store/actions/users";
export default {
  name: "Login",
  data() {
    return {
      show1: false,
      password_msg: "",
      form: {
        username: "",
        password: "",
      },
      errors: '',
    };
  },
  mounted() {
    this.$store.commit("app/SET_DRAWER", false);
  },
  validations() {
    return {
      form: {
        username: {
          required,
        },
        password: {
          required,
        },
      },
    };
  },
  methods: {
    KeyDown(event){
      if(event.key == 'Enter'){
        this.Submit()
      }
    },
    async Submit() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
      } else {
        let resp = await this.$dispatch(USER_LOGIN, this.form);
        if (resp.success) {
          window.location.assign(process.env.BASE_URL)
          // this.$router.push({ name: "Dashboard" });
        } else if (resp.fail) {
          this.errors = resp.result.non_field_errors[0];
        } else {
          this.errors = resp;
        }
      }
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.form.username.$dirty) return errors;
      !this.$v.form.username.required && errors.push("Номер телефона обязателен");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Необходим пароль");
      return errors;
    },
  },
};
</script>

<style scoped lang="scss">
.login-form {
  max-width: 500px;
  box-sizing: border-box;
  margin: auto;
  margin-top: 100px;
}
img {
  margin-left: auto;
}
.d-block{
  display: block !important;
  text-align: center !important;
}
</style>
